import $ from 'jquery'
import "./src/js/modernizr"
import "./src/js/lazy"
import "popper.js"
import "bootstrap"

import "./src/scss/index.scss"
import "./src/component/header"
import "./src/component/swiper"
import "./src/component/footer"

document.addEventListener('contextmenu', event => event.preventDefault())