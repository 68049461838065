import Swiper from "swiper/swiper-bundle"
import 'swiper/swiper-bundle.css';
import "./swiper.scss"

const mainSwiper = new Swiper('.slider-component .swiper-container', {
  spaceBetween: 0,
  lazy: true,
  loop: true,
  effect: 'fade',
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.slider-component .swiper-button-next',
    prevEl: '.slider-component .swiper-button-prev',
  }
})

const altSwiper = new Swiper('.slider-alt-component .swiper-container', {
  spaceBetween: 20,
  slidesPerView: 'auto',
  loop: true,
  navigation: {
    nextEl: '.slider-alt-component .swiper-button-next',
    prevEl: '.slider-alt-component .swiper-button-prev',
  },
  pagination: {
    el: 'slider-alt-component .swiper-pagination',
    clickable: true
  },
})