import $ from 'jquery'

var lazyLoad = function(img) {
  var esp = img.offset().top; // element scroll pos
  var bodyRect = document.body.getBoundingClientRect();

  var wh = window.innerHeight; // window height
  var scPos = bodyRect.y * -1; // scroll position
  var view = wh + scPos + 100;
  var loaded = false

  if (esp < view ) {
    loaded = true
  }

  return loaded
}

var loaded = function (img, src, isLoaded, inScreen, type) {
  if(!isLoaded && inScreen) {
    if (type) {
      img.attr('src', src)
    } else {
      img.css('background-image', src)
    }
    img.addClass('lazy-loaded')
  }
}

var lazyImg = function () {
  $('img.lazy-img').each(function () {
  
    const img      = $(this)
    const isLoaded = img.hasClass('lazy-loaded')
    const src      = img.data('src')
    const inScreen = lazyLoad(img)
  
    loaded(img, src, isLoaded, inScreen, true)
  
  })
}

var lazyBg = function () {
  $('.lazy-bg').each(function () {
  
    const img      = $(this)
    const isLoaded = img.hasClass('lazy-loaded')
    const src      = img.data('src')
    const inScreen = lazyLoad(img)
  
    loaded(img, src, isLoaded, inScreen, false)
  
  })
}

setTimeout(() => {
  lazyImg()
  lazyBg()
}, 200)

$(window).on('scroll', function () {
  lazyImg()
  lazyBg()
})

$(window).on('resize', function () {
  lazyImg()
  lazyBg()
})



$(window).on('load', function () {
  setTimeout(() => {
    $('.preloader').remove()
  }, 200)
})